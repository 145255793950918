import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { of as observableOf, Observable } from 'rxjs';
import { BaseResponseLogin, Login } from '../model/user';
import { UserService } from './user.service';
import { BaseResponseLogistic, LogisticItem } from '../model/logistic';


@Injectable()
export class LogisticService {

    public currentCode: LogisticItem;

    constructor(private httpClient: HttpClient, private userService: UserService) {
    }

    /**
     * POST: get logistic info
     * @param code : string code without https://...
     */
    getLogistic(code: string): Observable<BaseResponseLogistic> {
        let endpoint = environment.endpoint;

        let data = { code: code };

        const httpOptions = {
            headers: new HttpHeaders({
                'x-token': this.userService.token,
            })
        };
        //
        //return this.httpClient.get<BaseResponseUser>("./assets/mock/login.json")
        return this.httpClient.post<BaseResponseLogistic>(`${endpoint}logistic/LogisticData`, data, httpOptions).pipe(map((data) => {
            let resp = <BaseResponseLogistic>data;
            if (resp.status) {
                return resp;
            } else {
                throw resp.message;
            }
        }), catchError((err: HttpErrorResponse) => {
            if (err.status != 500) {
                throw err;
              } else{
                  throw err.error.error;
              } 
        }));
    }
}