import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { of as observableOf, Observable } from 'rxjs';
import { BaseResponseLogin, Login } from '../model/user';


@Injectable()
export class UserService {


    public token: string = "";

    constructor(private httpClient: HttpClient) {
    }

    /**
     * POST: login form
     * @param data : Login data
     */
    login(data: Login): Observable<BaseResponseLogin> {
        let endpoint = environment.endpoint;

        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${this.token}`,
            })
        };

        //
        //return this.httpClient.get<BaseResponseUser>("./assets/mock/login.json")
        return this.httpClient.post<BaseResponseLogin>(`${endpoint}authorization/applogin`, data, httpOptions).pipe(map((data) => {
            let resp = <BaseResponseLogin>data;
            if (resp.status) {
                this.token = resp.token;
                return resp;
            } else {
                throw resp.message;
            }
        }), catchError((err: HttpErrorResponse) => {
    
            if (err.status != 500) {
              throw err;
            } else{
                throw err.error.error;
            } 
        }));
    }

    logout(): Observable<any> {
        this.token = "";
        return observableOf("ok");
    }
}