import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { LayoutComponent } from './components/layout/layout.component';
import { ReadComponent } from './pages/read/read.component';
import { AuthGuard } from './@core/utils/auth.gard';


const routes: Routes = [
  { component: LoginComponent, path: 'login' },
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: 'app',
    component: LayoutComponent, canActivate: [AuthGuard],
    children: [
      { path:'lettura', component: ReadComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


