<div id="bg-login" class="container-fluid text-center">
  <div class="row align-items-center mx-auto">
    <div class="col-12 text-center">
      <img src="/assets/logo.png" class="img-fluid mx-auto logo">
      <div class="card mt-3">
        <div class="card-body"><h1>Vericode Check</h1>
      <p>Please, login to access the site.</p>

      
          <form class="text-left form-login">
            <div class="form-group">
              <label for="email">Email</label>
              <input type="email" class="form-control" placeholder="Email" id="username" name="username"
                [(ngModel)]="user.username">
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input type="password" class="form-control" id="password" name="password" [(ngModel)]="user.password">

            </div>

            <button type="submit" class="btn btn-primary btn-block" (click)="login()">Login</button>

            <div class="alert alert-danger mt-3" role="alert" *ngIf="messageError != ''">
              {{ messageError }}
            </div>
            
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
