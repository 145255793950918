<nav class="navbar navbar-light bg-light">
  <a class="navbar-brand" href="#">
    <img src="/assets/logo.png" class="img-fluid mx-auto logo">
  </a>
</nav>
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <router-outlet #rt></router-outlet>
    </div>
  </div>
</div>
