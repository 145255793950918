import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Login, LoginClass, BaseResponseLogin } from 'src/app/@core/model/user';
import { UserService } from 'src/app/@core/services/user.service';
import { Dictionary } from 'src/app/@core/utils/dictionary';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  messageError:string = "";
  user: Login = new LoginClass();


  constructor(private route:Router, private service: UserService) { }

  ngOnInit() {
  }

  login(){
    this.messageError = "";
    this.service.login(this.user)
    .subscribe((data: BaseResponseLogin) => {
      console.log("LOGIN: token - " + data.token);
      this.route.navigateByUrl('/app/lettura');
    }, (err) => {
      this.messageError = Dictionary.translate(err);
      console.log("Error Login", err);
      
    });
  }

}
