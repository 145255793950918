<div class="container">
  <div class="row align-items-center">
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="card mt-3">
            <div class="card-body">
              <h5 class="card-title">Select the camera</h5>
              <div class="alert alert-danger" role="alert" *ngIf="hasPermission === false">
                Permission to access the camera has been denied. <br>
                Doing so will not be able to use the code scanning application.
              </div>
              <div class="alert alert-danger" role="alert" *ngIf="hasDevices === undefined">
                Unable to check for a camera. <br>
                The access permission may have been denied.
              </div>
              <div class="alert alert-danger" role="alert" *ngIf="hasDevices === false">
                No cameras found.
              </div>

              <p class="card-text">Choose which camera to use.</p>
              <select (change)="onDeviceSelectChange($event.target.value)" class="form-control">
                <option value="" [selected]="!currentDevice">No Device Selected</option>
                <option *ngFor="let device of availableDevices" [value]="device.deviceId"
                  [selected]="currentDevice && device.deviceId === currentDevice.deviceId">{{device.label}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">

          <div class="card mt-3" *ngIf="qrResultString">
            <div class="card-body">
              <h5 class="card-title">Code read</h5>
              <div class="d-block text-right">
                <button type="submit" class="btn btn-primary" (click)="clearResult()">Clean</button>
              </div>
              <p class="card-text">The read code is: <b>{{ qrResultString }}</b></p>

              <div class="row my-3 align-items-center" *ngIf="current">
                <div class="col-6" *ngIf="current.Containers && current.Containers.length >0">
                  <button type="button" class="btn btn-outline-primary btn-block" (click)="openModal(logistica)">
                    <h4><i class="fas fa-box"></i></h4>Logistic info
                  </button>
                </div>
                <div class="col-6" *ngIf="current.ShippingDocument">
                  <button type="button" class="btn btn-outline-primary btn-block" (click)="openModal(spedizione)">
                    <h4><i class="fas fa-shipping-fast"></i></h4>Shipping info
                  </button>
                </div>
              </div>

              <div class="alert alert-danger mt-3" role="alert" *ngIf="invalidCode === true">
                Invalid code.
              </div>
              <div class="alert alert-danger mt-3" role="alert" *ngIf="messageError != ''">
                {{ messageError }}
              </div>

            </div>
          </div>

          <div class="card mt-3">
            <div class="card-body">
              <h5 class="card-title">Frame the code</h5>
              <p class="card-text">Frame the code to check.</p>
              <zxing-scanner [formats]="formatsEnabled" [(device)]="currentDevice" (scanSuccess)="onCodeResult($event)"
                (camerasFound)="onCamerasFound($event)"></zxing-scanner>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #logistica>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Logistic info</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="current.Containers && current.Containers.length >0">
    This product is contained within the following items:
    <div class="alert alert-secondary my-2" role="alert" *ngFor="let c of current.Containers">
      <b>Type: </b> {{ c.TypeDescription }}<br>
      <b>Code: </b> {{ c.Code }}
    </div>
  </div>
</ng-template>

<ng-template #spedizione>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Shipping Info</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="current.ShippingDocument">
    Shipping Document: <b>{{ current.ShippingDocument.DocTypeDescription }}</b><br>
    <table class="table mt-3">
      <tbody>
        <tr *ngIf="current.ShippingDocument.DocDate && current.ShippingDocument.DocDate.trim() != ''">
          <th scope="row" class="table-secondary">Document Date</th>
          <td>{{ current.ShippingDocument.DocDate }}</td>
        </tr>
        <tr *ngIf="current.ShippingDocument.DocNumber && current.ShippingDocument.DocNumber.trim() != ''">
          <th scope="row" class="table-secondary">Document number</th>
          <td> {{ current.ShippingDocument.DocNumber }}</td>
        </tr>
        <tr *ngIf="current.ShippingDocument.ClientCode && current.ShippingDocument.ClientCode.trim() != ''">
          <th scope="row" class="table-secondary">Client code</th>
          <td> {{ current.ShippingDocument.ClientCode }}</td>
        </tr>
        <tr
          *ngIf="current.ShippingDocument.ClientDescription && current.ShippingDocument.ClientDescription.trim() != ''">
          <th scope="row" class="table-secondary">Client</th>
          <td> {{ current.ShippingDocument.ClientDescription }}</td>
        </tr>
        <tr>
          <th scope="row" class="table-secondary">Client Address</th>
          <td>
            <p>{{ current.ShippingDocument.ClientAddress1 }} <br>
              {{ current.ShippingDocument.ClientAddress2 }} <br>
              {{ current.ShippingDocument.ClientCity }}
              {{ current.ShippingDocument.ClientZipCode }}
              {{ current.ShippingDocument.ClientState }}
              {{ current.ShippingDocument.ClientCountry }}
            </p>
          </td>
        </tr>
        <tr *ngIf="current.ShippingDocument.RecipientCode && current.ShippingDocument.RecipientCode.trim() != ''">
          <th scope="row" class="table-secondary">Recipient Code</th>
          <td> {{ current.ShippingDocument.RecipientCode }}</td>
        </tr>
        <tr
          *ngIf="current.ShippingDocument.RecipientDescription && current.ShippingDocument.RecipientDescription.trim() != ''">
          <th scope="row" class="table-secondary">Recipient</th>
          <td> {{ current.ShippingDocument.RecipientDescription }}</td>
        </tr>
        <tr>
          <th scope="row" class="table-secondary">Recipient Address</th>
          <td>
            <p>{{ current.ShippingDocument.RecipientAddress1 }} <br>
              {{ current.ShippingDocument.RecipientAddress2 }} <br>
              {{ current.ShippingDocument.RecipientCity }}
              {{ current.ShippingDocument.RecipientZipCode }}
              {{ current.ShippingDocument.RecipientState }}
              {{ current.ShippingDocument.RecipientCountry }}
            </p>
          </td>
        </tr>
        <tr
          *ngIf="current.ShippingDocument.LogisticOperatorCode && current.ShippingDocument.LogisticOperatorCode.trim() != ''">
          <th scope="row" class="table-secondary">Logistic operator</th>
          <td> ( {{ current.ShippingDocument.LogisticOperatorCode }} ) -
            {{ current.ShippingDocument.LogisticOperatorDescription }}</td>
        </tr>
        <tr *ngIf="current.ShippingDocument.Content">
          <th scope="row" class="table-secondary">Shipping content</th>
          <td>
            <div *ngFor="let c of current.ShippingDocument.Content">
              <p>{{ c.Code }}</p>
            </div>
          </td>
        </tr>

      </tbody>
    </table>
  </div>
</ng-template>
