import { BaseResponse } from './global';
import { environment } from 'src/environments/environment';

export interface Login {
    username: string,
    password: string,
    CompanyId: number,
    lang: string
}

export class LoginClass implements Login {
    username: string = "";
    password: string = "";
    CompanyId: number = environment.CompanyId;
    lang: string = environment.lang;
    constructor(){ }
}

export interface BaseResponseLogin extends BaseResponse {
    token: string
}