import { environment } from 'src/environments/environment';

export class Dictionary {
    public static Lang: string = environment.lang;

    public static Elements: Array<{ key: string, it: string, en: string }> = [
        { key: 'need_verification', it: 'Utenza in attesa di verifica.', en: 'User without verification.' },
        { key: 'invalid_request', it: 'Utenza non valida.', en: 'Ivalid user.' },
        { key: 'unauthorized', it: 'Utenza non autorizzata.', en: 'Unauthorized User.' },
        { key: 'wrong_user_password', it: 'Username o password errati.', en: 'Wrong Username or Password.' },
        { key: 'code_not_found', it: 'Codice richiesto non trovato.', en: 'Code not found.' },
        { key: 'needs_update', it: 'E\'disponibile un nuovo aggiornamento, è necessario aggiornare l\'applicazione.', en: 'A new update is available, the application must be updated' },
        { key: 'app_blocked', it: 'L\'applicazione è al momento non disponibile.', en: 'The application is currently not available.' },
        { key: 'no_application', it: 'L\'applicazione non è registrata.', en: 'Application is not registered.' },
        { key: 'no_validazione', it: 'Impossibile verficare il codice letto.', en: 'Unable to verify the read code.' },


    ];
    public static translate(key: string) {
        if (key != undefined) {
            let keySearch = key.toLowerCase();
            var translation = Dictionary.Elements.filter((el) => {

                return el.key.toLowerCase() == keySearch;
            });
            if (translation == undefined || translation.length == 0) {
                return key;
            } else {
                return translation[0][Dictionary.Lang];
            }
        }
        else {
            return "";
        }
    }

}