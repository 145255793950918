import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({providedIn:'root'})
export class AuthGuard implements CanActivate{
    path: ActivatedRouteSnapshot[];
    route: ActivatedRouteSnapshot;
    constructor(
        private router: Router,
        private userService: UserService
        ){}

    async canActivate(route: ActivatedRouteSnapshot, state:RouterStateSnapshot){
        if(this.userService.token != undefined && this.userService.token != ""){
            return true;
        }else{

            this.router.navigateByUrl('');
            return false;
        }
        
    }

    
}