import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/@core/services/user.service';
import { BarcodeFormat } from '@zxing/library';
import { LogisticService } from 'src/app/@core/services/logistic.service';
import { BaseResponseLogistic, LogisticItem } from 'src/app/@core/model/logistic';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Dictionary } from 'src/app/@core/utils/dictionary';

@Component({
  selector: 'app-read',
  templateUrl: './read.component.html',
  styleUrls: ['./read.component.scss']
})
export class ReadComponent implements OnInit {

  messageError: string = "";
  qrResultString: string;
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;

  hasDevices: boolean;
  hasPermission: boolean;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  invalidCode: boolean = false;
  current: LogisticItem;

  modalRef: BsModalRef;

  constructor(private route: Router, private service: LogisticService, private modalService: BsModalService) { }

  ngOnInit() {

  }

  login() {

  }
  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.currentDevice = device || null;
  }

  onCodeResult(resultString: string) {
    this.invalidCode = false;
    this.qrResultString = resultString;
    let code = null;
    if (this.qrResultString.includes("https://vrcd.it/")) {
        code = this.qrResultString.replace("https://vrcd.it/", "");}
      else{
        code = this.qrResultString;
      }
      this.messageError = "";
      this.service.getLogistic(code)
        .subscribe((data: BaseResponseLogistic) => {
          this.service.currentCode = data.Result;
          this.current = data.Result;
          console.log("LOGISTIC: " + (this.current.Containers && this.current.Containers.length >0));
        }, (err) => {
          this.messageError = Dictionary.translate(err);
          console.log("Error Login", err);
          this.service.currentCode = null;
          this.current = null;

        });
  }

  clearResult(): void {
    this.qrResultString = null;
    this.current.Containers = null;
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }





}
